





















































































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import IBell from '@/assets/icons/Bell.vue';

import MethodsPayment from '../../components/MethodsPayment/index.vue';
import PurchaseDetails from '../../components/PurchaseDetails/index.vue';
import AddressDetails from '../../components/AddressDetails/index.vue';
import BannerPayment from '../../components/BannerPayment/index.vue';
import ModalCanceledPayment from '../../components/ModalCanceledPayment/ModalCanceledPayment.vue';
import PendencyDetailLoading from './PendencyDetailLoading.vue';

import Copy from '@/assets/icons/Copy.vue';

import { PaymentSuccess } from '../../interfaces/IPayment';

import PaymentService from '@/services/Payment/PaymentService';

import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';
import ActiveModal from '@/share/Util/ActiveModal';

import { METHOD_BY_BILLET } from '../../constants/index';

const PAYMENT_STATUS_PENDING = 'pending';

@Component({
  components: {
    PageHeader,
    Footer,
    PendencyDetailLoading,
    MethodsPayment,
    PurchaseDetails,
    AddressDetails,
    BannerPayment,
    Copy,
    FeedbackUser,
    ModalCanceledPayment,
    IBell
  },
  filters: {
    formattedDate(date: string) {
      if (!date) return '';

      const {
        day, month, year, hours, minutes 
      } = getDataSeparatedFromDateTime(date);

      return `${day}/${month}/${year} às ${hours}:${minutes}`;
    }
  }
})
export default class PendencyDetail extends Vue {
  private isLoading = false;

  private paymentData: PaymentSuccess | null = null;

  private PaymentService = new PaymentService();
  private setModal = new ActiveModal();

  async created() {
    await this.getPayment();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async getPayment() {
    try {
      this.isLoading = true;

      const [payment]: PaymentSuccess[] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS_PENDING
      });

      if (payment) {
        this.paymentData = payment;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao retornar os dados do pagamento.',
        status: 'error'
      });

      console.error({ error });
    } finally {
      this.isLoading = false;
    }
  }

  handleClickPay() {
    this.$router.push({
      name: this.paymentData?.payment.method === METHOD_BY_BILLET ? 'PaymentBillet' : 'PaymentPix',
      params: {
        feature: 'plano',
        productId: String(this.paymentData?.payment.productID),
        idPurchase: String(this.paymentData?.payment.ID)
      }
    });
  }

  async handleClickCancel() {
    try {
      await this.PaymentService.canceledPayment(Number(this.paymentData?.payment.ID));

      this.redirectStore();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao cancelar o pagamento.',
        status: 'error'
      });

      console.error({ error });
    }
  }

  redirectStore() {
    this.$router.push({
      name: 'Store'
    });
  }

  handleClickModalCancel() {
    this.setModal.activeModal('ModalCanceledPayment');
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Loja', to: '/loja' },
      { title: 'Pendência', to: null }
    ]);
  }
}
